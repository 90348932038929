import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import GetStarted from '../../sections/common/GetStarted';
import Page from '../../sections/common/Page';
import PageHero from '../../sections/common/PageHero';
import RichText from '../../sections/common/RichText';
import { PagedAPIResult } from '../../types/api/commonTypes';
import { PropertyGroupAPIResult, PropertyGroupSearchAPIResult } from '../../types/api/propertyGroupTypes';
import { Localized } from '../../types/cms/generalTypes';
import { RentalOverviewCMSResult, RentalPageCMSResult } from '../../types/cms/rentalTypes';
import { SearchCMSResult } from '../../types/cms/searchTypes';
import { LanguageKey } from '../../types/common';
import { SharedData } from '../../types/serverData';
import { loadFromApi, loadFromCms, loadSharedDataFromCMS } from '../../utils/http';
import { createLanguagePaths, getRoute } from '../../utils/languages';
import RentalHousingMap from '../../sections/rental-housing/RentalHousingMap';
import FAQs from '../../sections/homepage/FAQs';
import { HomepageCMSResult } from '../../types/cms/homepageTypes';
import RelatedArticles from '../../sections/common/RelatedArticles';
import { PropertiesGridProps } from 'components/PropertiesGrid';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getSlugPropertiesServerSide } from 'hooks/usePropertiesSearch';
import { SearchPropertiesResponse } from 'tenant-client/models/SearchPropertiesResponse';
import { createPostalCodeRange } from 'utils/common';
import LandingPagePropertyGrid from 'components/PropertiesGrid/LandingPagePropertyGrid';

const FAQsWrapper = styled.div`
  > div {
    border-top: 1px solid ${(props) => props.theme.color.gray30};
  }
`;

interface RentalProps extends SharedData {
  rentalOverview: RentalOverviewCMSResult;
  rentalPage: RentalPageCMSResult;
  pageData: SearchCMSResult;
  propertyGroupsData: PropertyGroupSearchAPIResult[];
  faqsData: HomepageCMSResult;
  propertiesGridProps: PropertiesGridProps;
}

const Rental = ({
  headerData,
  footerData,
  generalData,
  rentalOverview,
  rentalPage,
  propertyGroupsData,
  faqsData,
  languagePaths,
  propertiesGridProps,
}: RentalProps) => {
  const router = useRouter();
  const image = rentalPage.Image.data.attributes;
  const { locale } = router;
  const { properties, page, pageSize, columns, totalPropertiesCount } = propertiesGridProps;

  return (
    <Page header={{ data: headerData, options: 'white' }} footer={{ data: footerData, languagePaths }}>
      <SEO
        title={rentalPage?.SEO.Title}
        description={rentalPage?.SEO.Description}
        languagePaths={languagePaths}
        images={[
          {
            url: image.url,
            alt: rentalPage.PageTitle,
            width: image.width,
            height: image.height,
          },
        ]}
      />
      <PageHero
        heading={`${rentalOverview.PreHeadline} ${rentalPage.PageTitle}`}
        text={rentalPage.Description}
        image={image.url}
        breadcrumbs={[{ title: rentalOverview.PageTitle, link: getRoute('/rental-housing', locale as LanguageKey) }, { title: rentalPage.PageTitle }]}
        white
      />
      <LandingPagePropertyGrid
        totalPropertiesCount={totalPropertiesCount}
        properties={properties}
        columns={columns}
        page={page}
        pageSize={pageSize}
        noPropertiesFoundMessage={generalData.Misc.NoPropertiesFound}
      />
      <div className="mb-14">{propertyGroupsData.length > 0 && <RentalHousingMap data={propertyGroupsData} generalTexts={generalData} />}</div>
      <RichText spacing="noTop" fromCMS={rentalPage?.Content} />
      {rentalPage?.RelatedArticles.Show && rentalPage?.RelatedArticles.Tag.data && (
        <RelatedArticles
          heading={generalData?.Misc.RelatedArticles}
          tag={rentalPage?.RelatedArticles.Tag.data.attributes}
          generalTexts={generalData}
        />
      )}
      <FAQsWrapper>
        <FAQs fromCMS={faqsData?.FAQs} />
      </FAQsWrapper>
      <GetStarted fromCMS={rentalOverview.GetStarted} />
    </Page>
  );
};

export default Rental;

function getEndpoint(postalCode: string | undefined, municipality: string | undefined) {
  if (postalCode) {
    return 'property-group/find-by-postalcodes-and-tags';
  }
  if (municipality) {
    return 'property-group/find-by-municipality-and-tags';
  }
  return 'property-group/find-by-tags';
}

export async function getServerSideProps({ locale, params, query }: GetServerSidePropsContext<{ slug: string }>) {
  try {
    const { slug } = params!;
    const sharedDataPromise = loadSharedDataFromCMS(locale as LanguageKey);
    const rentalOverviewPromise = loadFromCms<RentalOverviewCMSResult>('rental-overview', locale as LanguageKey, ['GetStarted.CTA', 'SEO.OGImage']);
    const rentalPagePromise = loadFromCms<Localized<RentalPageCMSResult>>(`rental-pages/${slug}`, locale as LanguageKey, [
      'Image',
      'Properties.Tags',
      'Properties.FirstPostalCode',
      'Properties.LastPostalCode',
      'RelatedArticles.Tag.Articles.Category',
      'RelatedArticles.Tag.Articles.Image',
      'SEO.OGImage',
    ]);
    const faqsDataPromise = loadFromCms<HomepageCMSResult>('homepage', locale as LanguageKey, ['FAQs.Questions']);
    const translationsPromise = serverSideTranslations(locale as LanguageKey);

    const [{ headerData, footerData, generalData }, rentalOverview, rentalPage, faqsData, translations] = await Promise.all([
      sharedDataPromise,
      rentalOverviewPromise,
      rentalPagePromise,
      faqsDataPromise,
      translationsPromise,
    ]);

    const { Municipality, PostalCode, Tags } = rentalPage.Properties;
    const endpoint = getEndpoint(PostalCode, Municipality);
    const tags = Tags?.map((tag) => tag.Entry);
    const zipCodes = PostalCode ? [PostalCode] : [];
    const page = query.page ? parseInt(query.page as string) : 1;

    const pageEntriesPromise: Promise<PagedAPIResult<PropertyGroupAPIResult>> = loadFromApi(endpoint, {
      municipality: Municipality,
      postalcodes: zipCodes,
      tags,
      page,
      pageSize: 9999,
    });

    const postalCodeRange = createPostalCodeRange(rentalPage.Properties.FirstPostalCode, rentalPage.Properties.LastPostalCode);

    const searchPropertiesResponsePromise: Promise<SearchPropertiesResponse> = getSlugPropertiesServerSide({ tags, postalCodeRange }, page, 24);

    const [{ pageEntries }, searchPropertiesResponse] = await Promise.all([pageEntriesPromise, searchPropertiesResponsePromise]);
    const propertyGroupsData: PropertyGroupSearchAPIResult[] = await Promise.all(
      pageEntries.map(async (propertyGroup) => await loadFromApi('property-group/find-public', { propertyGroupId: propertyGroup.id })),
    );
    const languagePaths = createLanguagePaths(rentalPage, locale as LanguageKey);

    return {
      props: {
        headerData,
        footerData,
        generalData,
        rentalOverview,
        rentalPage,
        propertyGroupsData,
        propertiesGridProps: { ...searchPropertiesResponse, columns: 3 },
        faqsData,
        languagePaths,
        ...translations,
      },
    };
  } catch (error) {
    console.error(error);
    return { notFound: true };
  }
}
